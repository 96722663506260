<template>
  <div class="campaigns">
    <div class="campaign-list">
      <div class="container">
        <div class="header-wrap cf">
          <div class="header-left cf">
            <img src="../../assets/icons/media.svg" />
            <h2>{{ $t("ads.editTitle") }}</h2>
          </div>
          <div class="header-right"></div>
        </div>

        <div v-if="loading">
          <div class="loading-wrap">
            <div class="loading-spinner"></div>
          </div>
        </div>
        <div v-else>
          <form @submit="update_advertisment" class="form" method="post">
            <div class="form-wrap">
              <label for="name"
                >{{ $t("global.name") }} <small>({{ $t("global.mandatory") }})</small></label
              >
              <input v-model="advertisment.name" class="form-input" type="name" name="name" id="name" />
            </div>

            <div v-if="advertisment.advertisment_type == 'image'" class="form-wrap">
              <label for="duration"
                >{{ $t("global.duration") }} (in {{ $t("global.seconds") }}) <small>({{ $t("global.mandatory") }})</small></label
              >
              <input v-model="advertisment.duration" class="form-input" type="duration" name="duration" id="duration" />
            </div>

            <p style="margin-bottom: 0; text-align: right">
              <input type="submit" :value="$t('global.saveNow')" class="button button-ci button-round button-100" />
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "advertisment",
  data() {
    return {
      loading: true,
      advertisment: {},
    };
  },
  methods: {
    get_data() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/advertisments/" + this.$route.params.uuid, { headers: { Authorization: this.$store.getters.getToken } })
        .then((response) => {
          console.log(response);
          this.loading = false;
          this.advertisment = response.data.advertisment;
        });
    },
    update_advertisment: function (e) {
      e.preventDefault();

      axios
        .patch(
          process.env.VUE_APP_BASE_API + "/advertisments/" + this.$route.params.uuid,
          {
            name: this.advertisment.name,
            duration: this.advertisment.duration,
          },
          { headers: { Authorization: this.$store.getters.getToken } }
        )
        .then((response) => {
          console.log(response);
          this.$notify({
            group: "notification",
            type: "success",
            text: this.$t("notification.saveSuccess"),
          });
          this.get_data();
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
